import { BffBrandType, FacilityFieldsFragment } from '../graphql/__generated/sdk';

export type TemplateWidth = 'Full' | 'Inset';
export type TemplateHeight = 'Standard' | 'Large';
export type TemplateSize = 'Standard' | 'Medium' | 'Large';
export type TAGEyebrowStyle = 'Standard' | 'Promo';
export type TAGContentAlignment = 'Left' | 'Center' | 'Right';
export type Sizes = 'Small' | 'Medium' | 'Large' | 'XLarge';

export const enum TAGBrand {
	AspenDental = 'aspendental',
	Motto = 'motto',
	WellNow = 'wellnow',
	TeamTAG = 'teamTag',
	AZPetVet = 'azPetVet',
	Chapter = 'chapter',
	ClearChoice = 'clearchoice',
	YouWellNow = 'youwellnow',
	LivWellNow = 'livwellnow',
	LobbyClearChoice = 'lobbyclearchoice',
	ConsultClearChoice = 'consultclearchoice',
	Lovet = 'lovet',
}

/* Contentful Types */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export type ContentfulLightBackground =
	| 'White'
	| 'Neutral'
	| 'Neutral Gradient'
	| 'Light'
	| 'Light Two'
	| 'Light Three'
	| 'Light Gradient'
	| 'Light Two Gradient'
	| 'Light Three Gradient'
	| 'Eucalyptus'
	| 'Rose'
	| 'Saffron'
	| 'Paper'
	| 'Accent Two Lightest';
export type ContentfulDarkBackground = 'Dark' | 'Dark Two' | 'Dark Gradient';
export type ContentfulBackground = ContentfulLightBackground | ContentfulDarkBackground | 'Transparent';
export type ContentfulSpacerSize = 'Small' | 'Standard' | 'Large' | 'XLarge';
export type DisableTemplatePaddingOn = Maybe<PaddingOnOption>;
export type PaddingOnOption = Maybe<string>[]; // Todo: should be 'Top', 'Bottom';
export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'normal';
export type TitleAlignment = 'Left' | 'Center';

export type TAGAspectRatio = '16:9' | '9:16' | '1:1';

export enum ContentAlignment {
	Left = 'flex-start',
	Center = 'center',
	Right = 'flex-end',
}

export type ContentfulButtonVariant =
	| 'Primary Default'
	| 'Primary High Contrast'
	| 'Primary Accent01'
	| 'Secondary Default'
	| 'Secondary High Contrast'
	| 'Tertiary Default'
	| 'Tertiary High Contrast';
export type ContentfulButtonSize = 'extraSmall' | 'small' | 'medium' | 'large';
export type ContentfulButtonIconPosition = 'start' | 'end';

export type ContentfulPageThemeOptions = 'Primary' | 'Allergy' | 'Occupational Medicine' | 'Virtual Care';
export type IFacilitySegmentCollection = string | null | undefined;
export type ContentVariable =
	| 'schedulingUrl'
	| 'facilityName'
	| 'facilityCode'
	| 'facilityCity'
	| 'facilityState'
	| 'facilityCityState'
	| 'address';

export type AppConfig = {
	prod: {
		ALERTS_API_URL: string;
		REVIEWS_API_KEY: string;
		REVIEWS_API_URL: string;
		ENTERPRISE_API_URL: string;
		REVIEWS_FACILITY_API_URL: string;
		PROMOTIONS_AND_OFFERING_API_URL: string;
		ENTERPRISE_API_GRAPHQL: string;
	};
	nonprod: {
		ALERTS_API_URL: string;
		REVIEWS_API_KEY: string;
		REVIEWS_API_URL: string;
		ENTERPRISE_API_URL: string;
		REVIEWS_FACILITY_API_URL: string;
		PROMOTIONS_AND_OFFERING_API_URL: string;
		ENTERPRISE_API_GRAPHQL: string;
	};
};

export type ContentfulAppId =
	| 'TAG - Marketing'
	| 'ClearChoice - Marketing'
	| 'AZPetVet - Marketing'
	| 'Chapter - Marketing'
	| 'WellNow_Marketing'
	| 'AspenDental - Marketing';
export type TagThemeType = 'aspendental' | 'azPetVet' | 'teamTag' | 'wellnow' | 'chapter' | 'clearchoice' | 'lovet';

export type FacilityDetailsDefaultId = 'Default_AD' | 'Default_WN' | 'Default_CC';

export interface BrandThemeConfig {
	name: string;
	logo: {
		dark: string;
		light: string;
	};
	siteURL: string;
	services: {
		prod: {
			ENTERPRISE_API_GRAPHQL: string;
			REVSPRING_API_URL: string;
			CONTENTFUL_GRAPHQL_URL: string;
			ENTERPRISE_API_URL_V2: string;
			BEFFE_GRAPHQL_URL: string;
			FORM_EVENTS_URL: string;
			DENTRINO_API_URL: string;
			CLEARCHOICE_API_URL: string;
			GOOGLE_MAP_ID: string;
			GOOGLE_MAP_API_KEY: string;
		};
		nonprod: {
			ENTERPRISE_API_GRAPHQL: string;
			REVSPRING_API_URL: string;
			CONTENTFUL_GRAPHQL_URL: string;
			ENTERPRISE_API_URL_V2: string;
			BEFFE_GRAPHQL_URL: string;
			FORM_EVENTS_URL: string;
			DENTRINO_API_URL: string;
			CLEARCHOICE_API_URL: string;
			GOOGLE_MAP_ID: string;
			GOOGLE_MAP_API_KEY: string;
		};
	};
	features: {
		redirects: RedirectRule[];
		rewrite: Record<string, string>;
		favIcons: Record<string, string>;
		sitemap: {
			additionalSitemapIndexes: string[];
		};
		analytics: {
			vwoAccountId: string;
			gtmId: string;
			enabled: boolean;
			inline: boolean;
			active: boolean;
			facebookPixel: boolean;
		};
		monitoring: {
			bugSnagApiKey: string;
		};
		chat: {
			enabled: boolean;
			chatBotURL: string;
			isIconButton: boolean;
		};
		notFoundPage: {
			desktopBackgroundImage: string;
			mobileBackgroundImage: string;
		};
		footer: {
			lite: boolean;
			alt: boolean;
			withSimpleLayout: boolean;
		};
		livePreview: {
			local: string;
			stage: string;
			prod: string;
		};
		blogs: {
			root: string;
		};
		migration: {
			paths: string[];
		};
		otherApps: {
			facilityDetailsBasePath: string;
			schedulingPath: string;
		};
		providers: {
			root: string;
		};
		facilityDetailsDefaultId?: FacilityDetailsDefaultId;
		schema: {
			[key: string]: string[] | object;
		};
	};
	featureFlags: {
		continentalUsMapFallbackThreshold?: number;
		enforceContinentalUsZoom?: boolean;
		featureList: string[];
		onPageScheduling: boolean;
		onPageSchedulingLocations: string[];
		salesForceEvergageScript: string;
		hideSearchWithLocationCards: boolean;
		hideOdpHeaderLinks: boolean;
		providerHeroTheme: string;
		showMobileHeaderLocationBar: boolean;
		defaultToCdn: boolean;
		showHeaderWithLocationNav: boolean;
		useFacilityBeffeDataSource: boolean;
		showProviderPageCtaInMeetOurStaff: boolean;
		loadFacilityReviewsFromBeffe: boolean;
		showReadMoreForBioInMeetOurStaff: boolean;
		transitionallyUseBeffe: boolean;
		useInlineOneTrust: boolean;
		useSessionStorageFacilityCode: boolean;
		withLeadingProviderAssociations: boolean;
		doNotIndexWholeSite: boolean;
		showHeaderNavigationMenu: boolean;
	};
	schemaUrl: string;
	contentfulAppId: ContentfulAppId;
	themeId: TagThemeType;
	bffBrandType: BffBrandType;
	googleMapBusinessQuery: string;
}

export type RedirectRule = {
	permanent: boolean;
	source: string;
	destination: string;
};

export type TGender = 'M' | 'F' | 'U';

export type TableVariant = 'default' | 'compare' | 'mottoV2Compare' | 'simple';

export interface IglobalThis {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

type Geo = {
	lat: string;
	lng: string;
};

type Location = {
	address1?: string;
	address2?: string;
	street1: string;
	street2: string;
	city: string;
	state: string;
	stateCode?: string;
	zip: string;
	geo: Geo;
	id: number;
	line1: string;
	line2: string;
	zipCode: string;
};

type CenterLocation = {
	street1: string | null;
	street2: string | null;
	city: string | null;
	state: string | null;
	zip: string | null;
	geo: Geo;
};

type Center = {
	id: string | null;
	sfid: string | null;
	name: string | null;
	marketingName: string | null;
	legalName: string | null;
	location: CenterLocation;
	callCenterPhone: string | null;
	website: string | null;
	timeZone: string | null;
	colocation: boolean;
	fis: boolean;
	ptcWindow: number;
	fisExceptionWindow: number;
	nonFisExpansionDays: number;
	address?: {
		address1: string;
		zipCode: string;
	} | null;
};

export type TDoctor = {
	providerId: string;
	providerType: null;
	npi: string;
	firstName: string;
	lastName: string;
	name?: string | null;
	hireDate: Date;
	jobTitle: string;
	gender: string | null;
	employeeStatus: string | null;
	active: boolean;
	degreeName: string | null;
	graduationDate: Date;
	collegeName: string | null;
	collegeCity: string | null;
	collegeState: string | null;
	ethnicity: string | null;
	languages: string | null;
	biography: string;
	specialties: string | null;
	volunteerism: string | null;
	photoUrl: string;
};

export type TCity = {
	city: string;
	state: string;
	displayName?: string;
	location?: Location;
	stateAbbreviation?: string;
	centers?: Center[];
};

export type TState = {
	sfId: string | null;
	abbreviation: string;
	state: string;
};

export type TOfficeInfo = {
	doctors: Array<TDoctor>;
	address: Location;
	code: string;
	phoneNumber: string | null;
	faxNumber?: string | null;
	email?: string | null;
	landmarks?: string;
	bilingual?: boolean;
	legalBillingName?: string;
	name?: string;
	siteName?: string;
	region?: string | null;
	workingHours?: FacilityFieldsFragment['workingHours'];
};

export type TRating = {
	rating: number;
	reviewCount: number;
};

export type TReviews = {
	averageRating: number;
	businessId: string;
	reviewCount: number;
	ratings: Array<TRating>;
	reviews: Array<TReview>;
};

export type TReview = {
	comments: string | null;
	rating: number;
	reviewId: string;
	reviewTimestamp: string;
	source: string;
	reviewer: {
		firstName: string;
		lastName: string;
	};
};

export type TReviewsMode = 'list' | 'carousel';

export type TTabRowVariant = 'Standard' | 'Filled';

export type TTabsSize = 'Small' | 'Medium' | 'Large';

export type UserEventsAPIFormData = {
	form: {
		brand: string;
		product_line: string;
		form_title: string;
		form_description: string;
		form_type: string;
		session_id: string;
		form_channel: string;
		form_questions: Array<{
			question: string;
			answer: string | null;
			unique_label?: string;
		}>;
	};
};

export type TAccordionExpandIconType = 'Add/remove circle outline' | 'Expand';

export type TAccordionExpandIconPosition = 'Left' | 'Right';

export type TRoundedCornersSize = 'Small' | 'Medium' | 'Large';

export type TMarqueeHeight = 'Small' | 'Medium' | 'Large';

export type THeroContentWidth = 'Small' | 'Medium' | 'Large';

export type TContentMaxWidthSize = 'Medium' | 'Large';

export type SchedulingSystemType = 'Revspring' | 'Clockwise' | 'Clearchoice Legacy' | '#';
