import { dataLayerConfig } from '@/utils';

export type TQuestion = {
	question: string;
	questionId: string;
	required: boolean;
	type: 'MultiAnswer' | 'SingleAnswer';
	description?: string;
	eyebrow: string;
	options: Array<TOption>;
	validationMessage: string;
	answer: TOption | Array<TOption> | null;
};

export type TOption = {
	id: string;
	value: string;
};

export type TUser = {
	label: string;
	name: string;
	required: boolean;
	unique_label: string;
	type: 'Text' | 'Phone';
	value: null | string;
};

export type TQuestions = Array<TQuestion>;
export type TUsers = Array<TUser>;
export type TPartialOrFullPath = 'Partial' | 'Full';

export type TFormData = {
	form: {
		brand: string;
		form_title: string;
		product_line: string;
		form_description: string;
		form_type: string;
		form_channel: string;
		session_id: string | null;
		form_questions: TQuestions;
		user_form: {
			title: string;
			description: string;
			isSignatureEliteResult: boolean;
			fields: TUsers;
			submitButtonLabel: string;
			legalMessage: string;
		};
	};
};

export interface IStateObject {
	activeQuestionIndex: number;
	formData: TFormData | null;
	formType: 'Questions' | 'UserDetails' | 'DynamicForm';
	fieldHasError: boolean;
}

export type APIFormDataAD = {
	form: {
		brand: string;
		product_line: string;
		form_title: string;
		form_type: string;
		form_result: string;
		session_id: string;
		form_channel: string;
		form_questions: Array<{
			question: string;
			answer: string;
			unique_label?: string;
		}>;
	};
};

export type APIFormDataCC = {
	subject: string;
	answers: {
		[key: string]: string | string[];
	};
};

export const getPartialOrFullPath = (formQuestions: TQuestions) => {
	const teethCountQuestion = formQuestions.find((formQuestion) => formQuestion.questionId === 'teethCount');
	if ((teethCountQuestion?.answer as TOption)?.value === '4 or less') {
		return 'Partial';
	}
	return 'Full';
};

export const getResultType = (
	formQuestions: TQuestions,
	isPartialPathOrFullPath: TPartialOrFullPath,
	brand: string,
	isSignatureEliteResult = false
) => {
	if (brand === 'ClearChoice') {
		const creditScoreQuestion = formQuestions.find(
			(formQuestion) => formQuestion.questionId === 'current_credit_score'
		);
		const creditScoreAnswerId = (creditScoreQuestion?.answer as TOption)?.id;

		// these ids are for good and excellent credit score options and quiz json comes from contentful
		const is740Plus = creditScoreAnswerId === 'f6dac1dc-d2c6-11ef-bdc7-b931c7524565';
		const between700To739 = creditScoreAnswerId === 'b7bd45aa-bef9-11ef-8f60-45af9e09548c';

		if (is740Plus || between700To739) {
			return CC_GOOD_OR_EXCELLENT_CREDIT_SCORE_RESULT;
		}
		return CC_POOR_OR_FAIR_CREDIT_SCORE_RESULT;
	}

	const preventingCareQuestion = formQuestions.find((formQuestion) => formQuestion.questionId === 'preventingCare');
	const importantSolutionQuestion = formQuestions.find(
		(formQuestion) => formQuestion.questionId === 'importantSolution'
	);
	if (
		(preventingCareQuestion?.answer as TOption)?.value === 'The cost seems too high' &&
		(importantSolutionQuestion?.answer as TOption)?.value ===
			'Most budget-friendly option with flexible ways to pay'
	) {
		if (isPartialPathOrFullPath === 'Partial') {
			return Partial_Denture_Result;
		} else {
			return isSignatureEliteResult ? Signature_Elite_Result : Comfilytes_Denture_Result;
		}
	} else if (isPartialPathOrFullPath === 'Partial') {
		return Dental_Implants_Result;
	} else {
		return Implant_Dentures_Result;
	}
};

export const Partial_Denture_Result = {
	title: 'partial',
	resultPage: '/patient-resources/quiz-results/partial-dentures/',
};

export const Comfilytes_Denture_Result = {
	title: 'comfilytes',
	resultPage: '/patient-resources/quiz-results/comfilytes-dentures/',
};

export const Dental_Implants_Result = {
	title: 'implant',
	resultPage: '/patient-resources/quiz-results/single-tooth-implant-and-implant-bridge/',
};

export const Signature_Elite_Result = {
	title: 'signature elite',
	resultPage: '/patient-resources/quiz-results/signature-elite-dentures/',
};

export const Implant_Dentures_Result = {
	title: 'implant denture',
	resultPage: '/patient-resources/quiz-results/implant-dentures/',
};

export const CC_POOR_OR_FAIR_CREDIT_SCORE_RESULT = {
	title: 'dental implants',
	resultPage: '/quiz-results-smile-design/',
};

export const CC_GOOD_OR_EXCELLENT_CREDIT_SCORE_RESULT = {
	title: 'dental implants',
	resultPage: '/quiz-results-candidate/',
};

export const quizGA4DataLayerPush = (brand: string, event: string, formResult: string, formData: TFormData) => {
	dataLayerConfig({
		event: 'formSubmission',
		form_data: {
			brand: brand,
			product_line: formData.form.product_line,
			submission_date: new Date().toISOString(),
			form_title: formData.form.form_title,
			form_description: formData.form.form_description,
			form_type: formData.form.form_type,
			form_result: formResult,
			form_step: event,
		},
	});
};
