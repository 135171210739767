import { useMemo } from 'react';

import Head from 'next/head';

import { Maybe, Page } from '@/types/generated';
import { useAppContext } from '@/context/AppContextProvider.ctx';

export default function NextPageHead({
	title,
	indexed,
	description,
	canonical,
	breadcrumbs,
	breadcrumbTitle,
	path,
	originUrl,
	ogImage,
}: Partial<Page> & { breadcrumbs?: Maybe<Page>[]; originUrl: string; ogImage: string }) {
	const { config } = useAppContext();
	const { salesForceEvergageScript } = config.featureFlags;
	const { schema } = config.features;
	const { schemaUrl } = config;

	const breadcrumbItems = useMemo(() => {
		if (!breadcrumbs) return [];

		return breadcrumbs.map((breadcrumb, index) =>
			breadcrumb
				? {
						position: index + 1,
						'@type': 'ListItem',
						item: {
							'@id': `${originUrl}${breadcrumb.path ?? ''}`,
							name: breadcrumb.breadcrumbTitle,
						},
				  }
				: null
		);
	}, [breadcrumbs, originUrl]);

	let formattedCanonical = canonical;
	if (canonical && !canonical.endsWith('/')) {
		formattedCanonical = `${canonical}/`;
	}

	const ogUrl = formattedCanonical ?? schemaUrl;

	return (
		<Head>
			<title>{title}</title>
			{!indexed && <meta key="robots" name="robots" content="noindex,nofollow" />}
			{!indexed && <meta key="googlebot" name="googlebot" content="noindex,nofollow" />}
			{description && <meta name="description" content={description} />}
			{canonical ? (
				<link
					rel="canonical"
					href={canonical.substring(canonical.length - 1) !== '/' ? `${canonical}/` : canonical}
				/>
			) : (
				<link
					rel="canonical"
					href={
						path?.substring(path.length - 1) !== '/'
							? `${originUrl}${path as string}/`
							: `${originUrl}${path}`
					}
				/>
			)}
			{/* OG Tags */}
			{/* {ogImage && (
				<> */}
			<meta property="og:image" content={ogImage} />
			<meta property="og:image:secure_url" content={ogImage} />
			<meta property="og:title" content={title ?? ''} />
			<meta property="og:description" content={description ?? ''} />
			<meta property="og:url" content={ogUrl} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image" content={ogImage} />
			<meta name="twitter:title" content={title ?? ''} />
			<meta name="twitter:description" content={description ?? ''} />
			<meta name="twitter:url" content={canonical ?? schemaUrl} />

			{/* </>
			)} */}
			{salesForceEvergageScript === 'Async' ? (
				<script
					type="text/javascript"
					async
					src="//cdn.evgnet.com/beacon/aspendentalmgmtinc2/engage/scripts/evergage.min.js"
				/>
			) : salesForceEvergageScript === 'Sync' ? (
				<script
					type="text/javascript"
					src="//cdn.evgnet.com/beacon/aspendentalmgmtinc2/engage/scripts/evergage.min.js"
				/>
			) : null}
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: JSON.stringify({
						'@context': 'http://www.schema.org',
						'@type': 'WebPage',
						name: title || '',
						url: schemaUrl,
						description: description || '',
						...schema,
						...(breadcrumbs?.length && {
							breadcrumb: {
								'@type': 'BreadcrumbList',
								itemListElement: breadcrumbItems.concat([
									{
										position: breadcrumbItems.length + 1,
										'@type': 'ListItem',
										item: {
											'@id': schemaUrl,
											name: breadcrumbTitle,
										},
									},
								]),
							},
						}),
					}),
				}}
			/>
		</Head>
	);
}
