import React, { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { Options, RenderNode } from '@contentful/rich-text-react-renderer';
import { Theme } from '@mui/material/styles';
import parse from 'html-react-parser';

import {
	CardRichTextDescriptionLinks,
	SectionContentRichTextContentLinks,
	ContentTypeRichTextContentLinks,
	ContentTypeRichTextSecondaryContentLinks,
	TextBlockSeoContentLinks,
	TermsAndConditionsContentLinks,
	InsuranceIntroLinks,
	Page,
	TableFootnoteLinks,
	Video as IVideo,
	Maybe,
	BlogDetailsPage,
	Entry,
	ContentSectionDescriptionLinks,
} from '@/types/generated';
import { logWarning } from '@/utils/miscUtils';
import FocalPointImage from '@/components/FocalPointImage/';
import Table from '@/components/Table/';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import VideoContent from '@/components/VideoContent';

import ImageAsset from '../ImageAsset';

interface ContentfulRichTextNodeTarget {
	children: React.ReactNode;
	description: string;
	linkType: string;
	sys: {
		id: string;
		linkType: string;
		type: string;
	};
	file: {
		contentType: string;
		url: string;
	};
	filename: string;
	image: {
		title: string;
	};
	focalPoint: {
		x: number;
		y: number;
	};
	path: string;
}

interface HyperLinkEntry extends Entry {
	__typename: string;
}

const listSx: Record<string, unknown> = {
	display: 'inline-flex',
	flexDirection: 'column',
	alignItems: 'start',
	textAlign: 'left',
};

const parseChildren = (children: ReactNode): ReactNode => {
	// eslint-disable-next-line security/detect-unsafe-regex
	const regex = /\{(#[^:]*)?:?([^}]*)}*\}([^{]*)/g;
	const processNode = (node: ReactNode): ReactNode => {
		if (typeof node === 'string') {
			const matches = node.matchAll(regex);
			const elements = [];
			for (const match of matches) {
				elements.push({
					color: match[1],
					text: `${match[2]}${match[3]}`,
				});
			}
			if (elements.length) {
				const newSegments = elements.map((element, index) => {
					return (
						<span key={`${element.text}_${index}`} style={{ color: element.color || '' }}>
							{element.text}
						</span>
					);
				});
				return newSegments;
			}
			return node;
		}

		if (isValidElement(node)) {
			return cloneElement(node as ReactElement, {
				/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */
				children: React.Children.map(node.props.children, processNode),
			});
		}

		return node;
	};
	return React.Children.map(children, processNode);
};

export const getOptions = ({
	docProps,
	links,
	isDark = false,
	theme,
	isSmallScreen,
}: {
	docProps: Record<string, unknown>;
	links?:
		| CardRichTextDescriptionLinks
		| SectionContentRichTextContentLinks
		| ContentTypeRichTextContentLinks
		| TextBlockSeoContentLinks
		| TermsAndConditionsContentLinks
		| InsuranceIntroLinks
		| ContentTypeRichTextSecondaryContentLinks
		| TableFootnoteLinks
		| ContentSectionDescriptionLinks;
	isDark?: boolean;
	theme: Theme;
	isSmallScreen?: boolean;
}): Options => ({
	renderNode: {
		[BLOCKS.HEADING_1]: (_: unknown, children) => (
			<Typography component="h1" variant="header1" margin="0.625rem 0" {...docProps}>
				{parseChildren(children)}
			</Typography>
		),
		[BLOCKS.HEADING_2]: (_: unknown, children) => (
			<Typography component="h2" variant="header2" margin="0.75rem 0" {...docProps}>
				{parseChildren(children)}
			</Typography>
		),
		[BLOCKS.HEADING_3]: (_: unknown, children) => (
			<Typography component="h3" variant="header3" margin="0.875rem 0" {...docProps}>
				{parseChildren(children)}
			</Typography>
		),
		[BLOCKS.HEADING_4]: (_: unknown, children) => (
			<Typography component="h4" variant="header4" margin="1rem 0" {...docProps}>
				{parseChildren(children)}
			</Typography>
		),
		[BLOCKS.EMBEDDED_ASSET]: (node: Block) => {
			const isFullWidth = docProps.fullWidth;
			const assetsData = links?.assets?.block;

			if (!assetsData) {
				logWarning("RichText EMBEDDED_ASSET: Couldn't find assets data in links.");
				return null;
			}

			const target = node.data.target as ContentfulRichTextNodeTarget;

			if (target.sys.linkType !== 'Asset') {
				logWarning('RichText EMBEDDED_ASSET: target link type was not an Asset.');
				return null;
			}

			const asset = assetsData.find((asset) => asset && asset.sys.id === target.sys.id);

			if (!asset) {
				logWarning(
					`RichText EMBEDDED_ASSET: Couldn't find asset data for rich text content: ${target.sys.id}.`
				);
				return null;
			}

			if (asset.contentType === 'text/html') {
				return (
					<iframe
						width={isFullWidth ? '700' : '350'}
						height={isFullWidth ? '394' : '200'}
						src={`https://www.youtube.com/embed/${asset?.fileName as string}`}
						title={asset?.description ?? ''}
						frameBorder={0}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				);
			}

			if (
				['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'].includes(asset.contentType || '') &&
				asset.url
			) {
				return <img src={asset.url} alt={asset?.description ?? ''} style={{ maxWidth: '100%' }} />;
			}

			return null;
		},
		[BLOCKS.EMBEDDED_ENTRY]: (node: Block) => {
			const target = node.data.target as ContentfulRichTextNodeTarget;
			const targetSysId = target.sys.id;
			const blockEntries = links?.entries.block;
			const matchingBlockEntry = blockEntries?.find((entry: Maybe<Entry>) => entry?.sys.id === targetSysId);

			if (!matchingBlockEntry) {
				return;
			}

			// @ts-ignore
			if (matchingBlockEntry?.__typename === 'Table') {
				return <Table _id="" {...matchingBlockEntry} />;
			}

			// @ts-ignore
			if (matchingBlockEntry?.__typename === 'VideoAsset') {
				return (
					<VideoContent
						richTextStylePosition={'relative'}
						controllableVideo={
							// @ts-ignore
							isSmallScreen && (matchingBlockEntry.mobileVideo as IVideo)
								? // @ts-ignore
								  (matchingBlockEntry.mobileVideo as IVideo)
								: // @ts-ignore
								  (matchingBlockEntry.desktopVideo as IVideo)
						}
					/>
				);
			}

			// @ts-ignore
			if (matchingBlockEntry?.__typename === 'ImageAsset') {
				// @ts-ignore
				return (
					<Box sx={{ display: 'flex', justifyContent: 'left' }}>
						{/* @ts-ignore */}
						<ImageAsset {...matchingBlockEntry} dataTestId={`rich_text_image_asset_${targetSysId}`} />
					</Box>
				);
			}

			return (
				// @ts-ignore until it breaks
				<FocalPointImage image={target?.image} focalPoint={target?.focalPoint} {...docProps}>
					<>{target?.children}</>
				</FocalPointImage>
			);
		},
		[BLOCKS.PARAGRAPH]: (_: unknown, children) => (
			<Typography component="p" variant="bodyMediumBook" color="text.secondary" {...docProps}>
				{parseChildren(children)}
			</Typography>
		),
		[BLOCKS.OL_LIST]: (_: unknown, children) => (
			<Typography
				component="ol"
				variant="bodyMediumBook"
				color="text.secondary"
				{...{
					...docProps,
					...listSx,
					sx: {
						...(docProps.sx as Record<string, unknown>),
						...listSx,
						'& p': {
							textAlign: 'left',
						},
					},
				}}
			>
				{children as string}
			</Typography>
		),
		[BLOCKS.UL_LIST]: (_: unknown, children) => (
			<Typography
				component="ul"
				variant="bodyMediumBook"
				color="text.secondary"
				{...{
					...docProps,
					...listSx,
					sx: {
						...(docProps.sx as Record<string, unknown>),
						...listSx,
						'& p': {
							textAlign: 'left',
						},
					},
				}}
			>
				{children as string}
			</Typography>
		),
		[INLINES.HYPERLINK]: (node: Inline, children) => (
			<Link
				href={node.data.uri as string}
				color={isDark ? theme.palette.text.light : theme.palette.text.interactive}
				{...docProps}
			>
				{children as string}
			</Link>
		),
		[INLINES.ENTRY_HYPERLINK]: (node: Inline, children) => {
			const target = node.data.target as ContentfulRichTextNodeTarget;
			const entryData = links?.entries?.hyperlink;

			if (!entryData && target.sys.linkType === 'Entry') {
				return (
					<Typography variant="bodyMediumBook" color="text.secondary" {...docProps}>
						{children as string}
					</Typography>
				);
			}

			if (!entryData) {
				logWarning("RichText ENTRY_HYPERLINK: Couldn't find entries data in links.");
				return null;
			}

			if (target.sys.linkType !== 'Entry') {
				logWarning('RichText ENTRY_HYPERLINK: target link type was not an Entry.');
				return null;
			}

			const link = entryData.find((link) => link && link.sys.id === target.sys.id) as HyperLinkEntry;
			if (!link) {
				logWarning(
					`RichText ENTRY_HYPERLINK: Couldn't find entries data for rich text content: ${target.sys.id}.`
				);
				return null;
			}
			let path = '';
			const { config } = useAppContext();
			const blogsRootPath = config.features.blogs.root;
			if (link.__typename === 'BlogDetailsPage') {
				const blogDetails = link as BlogDetailsPage;
				const blogPath = blogDetails.path as string;
				path = `${blogsRootPath}${blogPath}/`;
			} else if (link.__typename === 'BlogListPage') {
				path = blogsRootPath;
			} else {
				const page = link as Page;
				path = page?.path || '';
			}
			if (!path) {
				logWarning(
					`RichText ENTRY_HYPERLINK: Couldn't find Page Path data for ${link.__typename} type rich text content: ${target.sys.id}.`
				);
				return null;
			}
			return (
				<Link
					href={path}
					color={isDark ? theme.palette.text.light : theme.palette.text.interactive}
					{...docProps}
				>
					{children as string}
				</Link>
			);
		},
	} as RenderNode,
	renderText: (text) => {
		// @see https://www.smartercontent.info/post/how-to-add-line-breaks-to-any-text-field-in-contentful
		return text.split('\n').reduce((children, textSegment, index) => {
			const parsedText = parse(textSegment) as React.ReactNode;
			return [...children, index > 0 && <br key={index} />, parsedText];
		}, [] as React.ReactNode[]);
	},
});
