import { DetailedHTMLProps, forwardRef, LinkHTMLAttributes, Ref } from 'react';

import { Box, BoxProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Head from 'next/head';

import { incorrectUsageWarning } from '@/utils/miscUtils';
import { optimizeImageUrl, optimizeSrcSet, useIsHighPriorityImage } from '@/utils/images/imageOptimization';

type ImgProps = JSX.IntrinsicElements['img'];

interface IBasicImage extends ImgProps {
	alt: string;
	ref?: Ref<HTMLImageElement>;
	quality?: number | string;
}

export const BasicImage: OverridableComponent<
	typeof Box & {
		props: BoxProps & IBasicImage;
		defaultComponent: 'img';
		ref?: Ref<HTMLImageElement>;
	}
> = forwardRef((props: BoxProps & IBasicImage, ref: Ref<HTMLImageElement> | null) => {
	const highPriority = useIsHighPriorityImage();

	const { src, ...otherProps } = props;
	if (!src) {
		// if there is no src assuming that image is being handled by css
		return <Box ref={ref} {...otherProps} />;
	}
	const width = otherProps.width && `${+otherProps.width}` === otherProps.width ? otherProps.width : undefined;
	const optimizedSrc = optimizeImageUrl(src, width, otherProps.quality);
	// add source set if sizes is defined
	let srcSet: { srcSet: undefined | string } = { srcSet: undefined };
	if (optimizedSrc.includes('webp') && otherProps.sizes) {
		srcSet = { srcSet: optimizeSrcSet(src, otherProps.quality) };
	}

	let imageSize = props.sizes;
	imageSize ||= props.width ? `${props.width}` : undefined;
	return (
		<>
			{highPriority ? <PreloadImage href={optimizedSrc} imageSrcSet={srcSet.srcSet} sizes={imageSize} /> : null}
			<Box
				ref={ref}
				component="img"
				loading={highPriority ? 'eager' : 'lazy'}
				fetchPriority={highPriority ? 'high' : undefined}
				{...srcSet}
				{...otherProps}
				src={optimizedSrc}
				alt={props.alt || ''}
			/>
		</>
	);
});
// @ts-ignore
BasicImage.displayName = 'BasicImage';

type preloadLinkProps = DetailedHTMLProps<LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>;

export function PreloadImage(props: preloadLinkProps) {
	return (
		<Head>
			<link rel="preload" as="image" {...props} />
		</Head>
	);
}

interface IImageWrapper {
	/**
	 * Custom image element to be used instead of the default one. If you want to use the default image element, do not pass this prop.
	 *
	 * @type {typeof BasicImage}
	 * @memberof IImageWrapper
	 */
	imageEl?: typeof BasicImage;
	boxSx?: BoxProps['sx'];
	[imageProps: string]: unknown;
}

export default function ImageWrapper({ imageEl: ImageElement, ...imageProps }: IImageWrapper) {
	if (!ImageElement) {
		return <BasicImage {...imageProps} alt={imageProps.alt as string} />;
	}
	const { boxSx, ...rest } = imageProps;

	if (boxSx) {
		incorrectUsageWarning(
			'ImageWrapper',
			`boxSx prop should not be used with custom image element.
				 If you want to use Sx styling use the default rendering method of ImageWrapper. 
				 If you want to pass styles to the custom image element, pass them as "style" props.`
		);
	}

	return <ImageElement {...rest} alt={imageProps.alt as string} />;
}
