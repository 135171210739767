import { SxProps } from '@mui/material';
import { SystemCssProperties, SystemStyleObject, Theme } from '@mui/system';

export const getIntrinsicRatioWrapperStyles = (
	paddingBottomHack: string,
	placeholderColor: SystemCssProperties<Theme>['background'],
	sxOverride: SxProps<Theme>
) => {
	return [
		{
			position: { xs: 'relative', md: 'static' },
			paddingBottom: paddingBottomHack,
			height: '100%',
			backgroundColor: placeholderColor,
		} as SystemStyleObject<Theme>,
		sxOverride,
	]
		.flatMap((v) => v)
		.filter((v) => v && typeof v === 'object');
};

const elementToStretch: SxProps = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
};

export const getImageWrapperStyles = (override: SxProps) => ({
	...elementToStretch,
	...override,
});
