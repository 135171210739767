import { Card, CardActions, CardContent, CardMedia, Typography, useTheme } from '@mui/material';

import { Card as TCard } from '@/types/generated';
import AspectRatioPaddingBox from '@/components/AspectRatioPaddingBox';
import RichText, { RichTextContent } from '@/components/RichText';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { HeadingTag } from '@/types';
import { textToJumpLink } from '@/utils';
import ContentfulButton from '@/components/ContentfulButton';
import ImageAsset from '@/components/ImageAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';

import { getCardMediaStyles } from '../CardItem/CardItem.styles';

import {
	getResourceCardStyles,
	getResourceCardContentStyles,
	getResourceCardActionStyles,
	getResourceCardTitle,
} from './ResourceCardTile.styles';

interface IResourceCardItem {
	card: TCard;
	isSmallScreen: boolean;
	useMediaRoundedCorners?: boolean;
}

export default function ResourceCardTitle({ card, isSmallScreen, useMediaRoundedCorners = false }: IResourceCardItem) {
	const theme = useTheme();
	const cardButtons = card?.buttonsCollection?.items;
	return (
		<Card
			data-test-id={`resource_card_tile_${card.sys.id}`}
			id={textToJumpLink(card.title)}
			sx={getResourceCardStyles({
				backgroundColor: card.backgroundColor,
			})}
		>
			{card.imageAsset && (
				<AspectRatioPaddingBox
					aspectRatio="5:4"
					contentfulAspectRatio={card.imageRatio}
					paddingBoxSx={{ ...getCardMediaStyles(useMediaRoundedCorners), height: 'auto' }}
				>
					<CardMedia
						component={card.imageAsset && card.buttonUrl ? 'a' : 'div'}
						href={card.buttonUrl || undefined}
						sx={{
							cursor: card.imageAsset && card.buttonUrl ? 'pointer' : 'default',
							overflow: 'hidden',
							...getCardMediaStyles(useMediaRoundedCorners),
						}}
						data-test-id={`resource_card_tile_image_asset_link_${card.sys.id}`}
					>
						<ImageAsset
							desktopImage={card.imageAsset.desktopImage as IImage}
							mobileImage={card.imageAsset.mobileImage as IImage}
							isBackgroundImage={false}
							dataTestId={`resource_card_img_asset_item_background_${card.sys.id}`}
						/>
					</CardMedia>
					<CardContent sx={getResourceCardContentStyles(isSmallScreen, theme, card.cardTextAlignment)}>
						{card.title && (
							<Typography
								variant={getHeadingVariant(card.titleHtag as HeadingTag)}
								sx={getResourceCardTitle(theme)}
								data-test-id={`resource_card_tile_title_${card.sys.id}`}
							>
								{card.title}
							</Typography>
						)}
						{card.description && (
							<Typography
								color={'text.light'}
								variant="bodySmallBook"
								data-test-id={`resource_card_tile_description_${card.sys.id}`}
								textAlign={card.cardTextAlignment === 'center' ? 'center' : 'left'}
							>
								{card.description}
							</Typography>
						)}
						{card.richTextDescription && (
							<RichText
								content={card.richTextDescription.json as RichTextContent}
								links={card.richTextDescription.links}
								docProps={{
									textAlign: card.cardTextAlignment === 'center' ? 'center' : 'left',
									color: 'text.light',
									variant: 'bodySmallBook',
								}}
							/>
						)}

						{cardButtons && cardButtons.length > 0
							? cardButtons.map((button) =>
									button ? (
										<CardActions
											key={button?.sys?.id}
											sx={getResourceCardActionStyles({
												cardActionAlignment: card.cardTextAlignment,
												isSmallScreen: isSmallScreen,
											})}
										>
											<ContentfulButton
												{...button}
												defaultVariant="secondaryDefault"
												dataTestId={`resource_card_tile_button_${button?.name || ''}`}
											/>
										</CardActions>
									) : null
							  )
							: null}
					</CardContent>
				</AspectRatioPaddingBox>
			)}
		</Card>
	);
}
