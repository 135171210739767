import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';

import { ContentfulBackground, DisableTemplatePaddingOn, Maybe, TemplateWidth, TemplateHeight, Sizes } from '@/types';
import {
	SingleMessage as TSingleMessage,
	TermsAndConditions as TTermsAndConditions,
	FocalPointImage as TFocalPointImage,
	SingleMessageButtonsCollection,
	SmallScreenContent,
	ImageAsset as TImageAsset,
} from '@/types/generated';
import { textToJumpLink, useTemplateContainerFullWidthStyles } from '@/utils';

import TAGSvgIcon, { IconName } from '../TAGSvgIcon';
import { resolveContentfulBgColor, translateTagColorNameForContentful } from '../../utils/resolveContentfulBgColor';
import TemplateContainer from '../TemplateContainer/TemplateContainer';

import SingleMessageContent from './SingleMessageContent';
import {
	templateWrapperStyles,
	getContentWrapperStyles,
	getContentAlignStyles,
	sideImageWrapperStyles,
	sideImageMobileWrapperStyles,
} from './SingleMessage.styles';
import { iconSizeNumber } from './SingleMessage.helpers';
import { RenderImage } from './RenderImage';
import { SideImage } from './SideImage';

export interface ISingleMessage extends TSingleMessage {
	/**
	 * Required field for the SingleMessage id
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	name?: Maybe<string>;
	/**
	 * Collection of SIngleMessage buttons
	 *
	 * @type {Maybe<SingleMessageButtonsCollection>}
	 * @memberof ISingleMessage
	 */
	buttonsCollection?: Maybe<SingleMessageButtonsCollection>;
	/**
	 * Background color for the single message section coming from Contentful.
	 *
	 * @type {ContentfulBackground}
	 * @memberof ISingleMessage
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies single message button size.
	 *
	 * @type {ContentfulButtonSize}
	 * @memberof ISingleMessage
	 */
	buttonSize?: Maybe<string>;
	/**
	 * Background image information for the hero section.
	 *
	 * @type {Maybe<TFocalPointImage>}
	 * @memberof ISingleMessage
	 */
	focalPointImage?: Maybe<TFocalPointImage>;
	/**
	 * Button text coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonText?: Maybe<string>;
	/**
	 * Button variant coming from Contentful. Can be 'Primary Default' or 'Secondary Default'.
	 *
	 * @type {Primary Default | Secondary Default}
	 * @memberof ISingleMessage
	 * @default 'Primary Default'
	 */
	buttonType?: Maybe<string>;
	/**
	 * Button Url coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonUrl?: Maybe<string>;
	/**
	 * Text content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Icon image coming from Contentful.
	 *
	 * @type {IconName}
	 * @memberof ISingleMessage
	 */
	icon?: Maybe<string>;
	/**
	 * Optional terms and conditions content coming from Contentful.
	 *
	 * @type {TTermsAndConditions}
	 * @memberof ISingleMessage
	 */
	/**
	 * Optional icon size for desktop
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	iconSize?: Maybe<string>;
	/**
	 * Optional terms and conditions displayed below content.
	 *
	 * @type {TTermsAndConditions}
	 * @memberof ISingleMessage
	 */
	tc?: Maybe<TTermsAndConditions>;
	/**
	 * Width of the SingleMessage template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Full'
	 */
	templateWidth?: Maybe<string>;
	/**
	 * Height of the SingleMessage template. Can be Regular or Full.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Regular'
	 */
	templateHeight?: Maybe<string>;
	/**
	 * Optional subtext content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	subText?: Maybe<string>;
	/**
	 * Optional description content coming from Contentful.
	 *
	 * @type {Maybe<Scalars['String']>}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	description?: Maybe<string>;
	/**
	 * Title content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	title?: Maybe<string>;
	/**
	 * Optional prop for determining SingleMessage content placement.
	 *
	 * @type {'row' | 'row-reverse'}
	 * @memberof ISingleMessage
	 * @default undefined
	 */
	direction?: Maybe<string>;
	/**
	 * Allows setting different content on small screens.
	 *
	 * @type {SmallScreenContent}
	 * @memberof ISingleMessage
	 * @default null
	 */
	contentSmallScreen?: Maybe<SmallScreenContent>;
	/**
	 * An option to disable left/right padding of content.
	 *
	 * @type {boolean}
	 * @memberof ISingleMessage
	 */
	disableGutters?: boolean;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof ISingleMessage
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * An option to either 'left' or 'center' align the Rich Text content
	 * @type {'left' | 'center'}
	 * @memberof ISingleMessage
	 * @default 'center'
	 */
	contentAlign?: Maybe<string>;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageAsset?: Maybe<TImageAsset>;
	/**
	 * Brandfolder Image to be displayed left of the body content.
	 * @type {Maybe<TImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageLeftSide?: Maybe<TImageAsset>;
	/**
	 * Brandfolder Image to be displayed right of the body content.
	 * @type {Maybe<TImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageRightSide?: Maybe<TImageAsset>;
	/**
	 * Specifies whether the content should grow vertically
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	contentFillVerticalSpace?: Maybe<boolean>;
	/**
	 * Specifies whether image is a banner above content
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isBannerImage?: Maybe<boolean>;
	/**
	 * Enables an inline layout for icon and button
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isInlineLayout?: Maybe<boolean>;
	/**
	 * Enables rounded corners for container
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	useRoundedCorners?: Maybe<boolean>;
}

function SingleMessage({
	name,
	contentSmallScreen,
	buttonsCollection,
	backgroundColor,
	disableGutters = false,
	disablePaddingOn,
	focalPointImage,
	templateHeight,
	templateWidth,
	contentAlign = 'center',
	buttonSize,
	buttonText,
	buttonType,
	buttonUrl,
	content,
	eyebrow,
	icon,
	iconSize = 'Large' as Sizes,
	sys,
	tc,
	title,
	imageAsset,
	imageLeftSide,
	imageRightSide,
	contentFillVerticalSpace,
	isBannerImage,
	isInlineLayout = false,
	useRoundedCorners = false,
}: Readonly<ISingleMessage>) {
	const { focalPointImageSmallScreen, eyebrowSmallScreen } = Object(contentSmallScreen) as SmallScreenContent;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isDarkBackground =
		backgroundColor?.toLowerCase().includes('dark') || backgroundColor?.toLowerCase() === 'saffron';
	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor || '') || '';
	const eyebrowText = isSmallScreen && eyebrowSmallScreen ? eyebrowSmallScreen : eyebrow;

	const bgColor = resolveContentfulBgColor(contentfulSafeBackgroundColor as ContentfulBackground, theme);

	const removePaddingFromContainer = isInlineLayout ? ['Top', 'Bottom'] : disablePaddingOn;
	const paddingStyles = useTemplateContainerFullWidthStyles(removePaddingFromContainer, isSmallScreen, true);
	const isContentCenterAligned = !contentAlign || contentAlign === 'center';

	const idLabel = textToJumpLink(name);

	const hasSideImage = !isInlineLayout && (imageLeftSide || imageRightSide);

	const singleMessageContentProps = {
		tc,
		sys,
		content,
		eyebrow,
		focalPointImage,
		buttonsCollection,
		contentSmallScreen,
		contentFillVerticalSpace,
		isContentCenterAligned,
		isInlineLayout,
		isSmallScreen,
		isDarkBackground,
		buttonSize,
		buttonText,
		buttonType,
		buttonUrl,
	};

	return (
		<TemplateContainer
			id={`single-message-${idLabel}`}
			maxWidth={false}
			contentfulBackgroundColor={bgColor}
			templateWidth={(templateWidth as TemplateWidth) || 'Full'}
			templateHeight={(templateHeight as TemplateHeight) || 'Standard'}
			dataTestId={`section_single_message_${sys.id}`}
			containerSx={{ ...templateWrapperStyles(templateWidth === 'Full', !!useRoundedCorners), ...paddingStyles }}
		>
			<RenderImage
				imageAsset={imageAsset}
				focalPointImage={focalPointImage}
				focalPointImageSmallScreen={focalPointImageSmallScreen}
				isBannerImage={isBannerImage}
				isSmallScreen={isSmallScreen}
			/>
			<Box data-test-id="section_single_message_alpha" sx={hasSideImage ? sideImageWrapperStyles : null}>
				{hasSideImage && !isSmallScreen && imageLeftSide && (
					<SideImage imageAsset={imageLeftSide} side={'left'} isSmallScreen={isSmallScreen} />
				)}
				<Box
					data-test-id="section_single_message_content"
					sx={getContentWrapperStyles(
						isSmallScreen,
						isContentCenterAligned,
						isInlineLayout,
						disableGutters,
						!!hasSideImage
					)}
				>
					{icon && (
						<Box
							data-test-id="icon_single_message_container"
							sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}
						>
							<TAGSvgIcon
								htmlColor={isDarkBackground ? 'neutrals.white' : 'tertiary.main'}
								data-test-id="icon_single_message"
								icon={icon as IconName}
								size={iconSizeNumber(iconSize as Sizes)}
							/>
						</Box>
					)}

					{eyebrow && (
						<Box
							data-test-id="text_single_message_eyebrow_container"
							sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}
						>
							<Typography
								data-test-id="text_single_message_eyebrow"
								variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
								color={isDarkBackground || focalPointImage ? 'text.light' : 'text.primary'}
								margin="0.75rem 0"
								letterSpacing="0.0625rem"
							>
								{eyebrowText}
							</Typography>
						</Box>
					)}

					<Typography
						variant={isSmallScreen ? 'header2' : 'heroMedium'}
						color={isDarkBackground ? 'text.light' : `primary.dark`}
						component="h1"
						textAlign={isSmallScreen ? 'center' : 'left'}
						marginBottom={'1rem'}
					>
						{title}
					</Typography>

					{hasSideImage && isSmallScreen && (
						<Box sx={{ ...sideImageMobileWrapperStyles }}>
							{imageLeftSide && (
								<SideImage imageAsset={imageLeftSide} side={'left'} isSmallScreen={isSmallScreen} />
							)}
							{imageRightSide && (
								<SideImage imageAsset={imageRightSide} side={'right'} isSmallScreen={isSmallScreen} />
							)}
						</Box>
					)}

					{(isInlineLayout && isSmallScreen && (
						<Box sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}>
							<SingleMessageContent {...singleMessageContentProps} />
						</Box>
					)) || <SingleMessageContent {...singleMessageContentProps} />}
				</Box>
				{hasSideImage && !isSmallScreen && imageRightSide && (
					<SideImage imageAsset={imageRightSide} side={'right'} isSmallScreen={isSmallScreen} />
				)}
			</Box>
		</TemplateContainer>
	);
}

export default SingleMessage;
