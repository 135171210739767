import { Theme } from '@mui/material';

export const videoStyles: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '0 auto',
	height: '100%',
	width: '100%',
	position: 'relative',
	objectFit: 'cover',
	objectPosition: 'center',
	top: 0,
	left: 0,
};

export const getHeroVideoControlsWrapperStyles = (theme: Theme, contentPosition?: string) => {
	return {
		width: { sm: '100%' },
		display: 'flex',
		position: 'absolute',
		zIndex: 10,
		lineHeight: 0,
		bottom: '0.75rem',
		...(contentPosition?.includes('Right')
			? {
					[theme.breakpoints.down('md')]: {
						flexDirection: 'row-reverse',
						right: '0.75rem',
					},
					[theme.breakpoints.up('md')]: {
						flexDirection: 'row',
						left: '0.75rem',
					},
			  }
			: {
					flexDirection: 'row-reverse',
					right: '0.75rem',
			  }),
	};
};

export const videoControlsWrapperStyles = {
	position: 'absolute',
	zIndex: 3,
	lineHeight: 0,
	bottom: '0.75rem',
	right: {
		xs: '0.75rem',
		sm: 'auto',
	},
	left: {
		xs: 'auto',
		sm: '0.75rem',
	},
};

export const videoControlIconStyles = {
	cursor: 'pointer',
	fontSize: '1.7rem',
	color: 'text.light',
};

export const backgroundImageStyles = {
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
	objectPosition: 'center',
};
