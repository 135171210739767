import { Card } from '@/graphql/__generated/sdk';
import { Maybe } from '@/types';

export const collectUniqueTags = (cards: Maybe<Card>[] | undefined): string[] => {
	const tagSet = new Set<string>();

	cards?.forEach((card) => {
		card?.tags?.forEach((tag) => tag && tagSet.add(tag));
	});

	return Array.from(tagSet);
};

export const filterCardsByTag = (
	cards: Maybe<Card>[] | undefined,
	tagName: string | null
): Maybe<Card>[] | undefined => {
	if (tagName) {
		return cards?.filter((card) => card?.tags?.includes(tagName));
	}

	return cards;
};

export const LIMIT_OF_MULTI_CARD_IN_ROW_FOR_DESKTOP = 4;
