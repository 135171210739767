import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
/**
 * Used to apply specific typography styles to button variants.
 *
 * @export
 * @param {Theme} theme
 * @param {string} variant
 * @return {*}  {React.CSSProperties}
 */
export function resolveTypographyVariantStyles(theme: Theme, variant: string): SystemStyleObject<Theme> {
	const styleFn = theme.components?.MuiTypography?.variants?.find((v) => v.props?.variant === variant)?.style;

	if (typeof styleFn === 'function') {
		return styleFn({ theme }) as SystemStyleObject<Theme>;
	}

	return {};
}

export function responsiveResolveTypographyVariantStyles(
	theme: Theme,
	mobileVariant: string,
	desktopVariant: string
): SystemStyleObject<Theme>[] {
	return [
		resolveTypographyVariantStyles(theme, mobileVariant),
		{
			[theme.breakpoints.up('md')]: {
				...resolveTypographyVariantStyles(theme, desktopVariant),
			},
		},
	];
}
