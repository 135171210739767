// Bugsnag
export const BUGSNAG_NOTIFY_URL = 'https://bugsnag-notify.aspendental.com';
export const BUGSNAG_SESSIONS_URL = 'https://bugsnag-sessions.aspendental.com';
export const BUGSNAG_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string;

export enum TagTheme {
	AspenDental = 'aspendental',
	AzPetVet = 'azPetVet',
	TeamTag = 'teamTag',
	WellNow = 'wellnow',
	Chapter = 'chapter',
	ClearChoice = 'clearchoice',
	ConsultClearChoice = 'consultclearchoice',
	LobbyClearChoice = 'lobbyclearchoice',
	YouWellNow = 'youwellnow',
	LivWellNow = 'livwellnow',
	Lovet = 'lovet',
}
// Add a few options for Cache Control
// CACHE_CONTROL_NO_CACHE: Used for API get requests that should not be cached
// CACHE_CONTROL_SHORT_TTL: Used for HTML Templates that should be cached for a short period of time
//                          Caching Notes:
//                           - 10 seconds for the browser, 60 seconds for the CDN, and 60 seconds for the CDN to revalidate
//                           - 0 seconds for the CDN to serve stale content if the origin is down
// Refer to: https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Expiration.html
export const CACHE_CONTROL_NO_CACHE = 'no-cache, max-age=0, must-revalidate';
export const CACHE_CONTROL_SHORT_TTL = 'public, max-age=10, s-maxage=60, stale-while-revalidate=60, stale-if-error=0';
export const HUMANKIND_LEAD_FORM_ID = '676ae200-b351-11ef-a647-e74fd84df8f4';

// Maps HK answer id's to CC_API answer id's
export const HUMANKIND_ANSWER_ID_MAP = {
	'2pRnmdh2yVvODBUkQcol6oFoPiy': 'bf11d2af-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnnocCIn1vLwDhpLNmxjNKTJX': 'bf11d2b0-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnoXPRafyh9PTxWaSqCJOtGWk': 'bf11d2b1-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnpNGpz3MkNIUeOtPEyNjLbhu': 'bf11d2b2-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnqL7l9GLzNo0zYXkophFHhuc': 'bf11d2b8-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnxAAzpjqGQCTeIRiJuRha3pJ': 'bf11d2b9-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnxhQYsD8dJf1C445Rg5fKoYH': 'bf11d2ba-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnxvaYS3ZuDLdqAmS1yDd7L8S': 'bf11d2bb-b3f9-11ef-a647-e74fd84df8f4',
	'2pRny68WcmgUzApprm9OjjGvxoN': 'bf11d2bc-b3f9-11ef-a647-e74fd84df8f4',
	'2pRnzYRCIPVWNO9fdgcDdIEQ4T3': 'bf11d2c2-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo2zsLnF8KylQfivx2GYcxDqr': 'bf11d2c3-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo3fVwqOVTw3SMdMO26IWwHlu': 'bf11d2c4-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo3lsh1VLz204xYq4FvaIZEXo': 'bf11d2c5-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo3rHCUj20myz0ZLEcM7BE4ng': 'bf11d2c6-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo48IRDSSJcq9KJJOQUbvwabN': 'bf11d2c7-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo8s0ULcgQCCYS9Dp8mx6lDAQ': 'bf11d2cc-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo9DerhcpIADMcBasuu3N8DRJ': 'bf11d2cd-b3f9-11ef-a647-e74fd84df8f4',
	'2pRo9LWhjRB4nbrE6TCC1tGh4kC': 'bf11d2ce-b3f9-11ef-a647-e74fd84df8f4',
	'2pRoAypcDzfr9kkQ7GjtCG6DoBG': 'bf11d2cf-b3f9-11ef-a647-e74fd84df8f4',
	'2pRoBLFL2jPvoLXmcOOsMr6DDtt': 'bf11d2d0-b3f9-11ef-a647-e74fd84df8f4',
	'2pRoFbe1uDqqR4GufDh5X9odgmR': 'bf11d2d6-b3f9-11ef-a647-e74fd84df8f4',
	'2pRoGMKGLifOrE6BbJRRqAdgMr6': 'f35a2112-b3fc-11ef-a647-e74fd84df8f4',
	'2pRoHAMu47z3ziuOFb4AqXAsfo6': 'f35a213a-b3fc-11ef-a647-e74fd84df8f4',
	'2pRoI4glULEA45Gsom4AEMJgG9C': 'f35a2144-b3fc-11ef-a647-e74fd84df8f4',
	'2pRoLlyUK8N7NyJc21SG6xreUma': 'f35a214e-b3fc-11ef-a647-e74fd84df8f4',
	'2pRoMctQpAnkdTV4y8PWRidWY9y': 'f35a2158-b3fc-11ef-a647-e74fd84df8f4',
	'2pRoOooYsuCuGmhf6nB2O2l9d8M': 'f35a2159-b3fc-11ef-a647-e74fd84df8f4',
	'2piA5W6wkGqMSRCSAUQFCZiYr7O': 'f35a2162-b3fc-11ef-a647-e74fd84df8f4',
};
