import { useState } from 'react';

import dynamic from 'next/dynamic';
import { Box, Container, Typography, useMediaQuery, useTheme, Chip } from '@mui/material';

import {
	Maybe,
	ImageAsset,
	MultiCardV2,
	MultiCardV2ButtonsCollection,
	MultiCardV2CardsCollection,
	MultiCardV2DescriptionRichText,
	ContentTypeRichTextContent as TContentTypeRichTextContent,
} from '@/types/generated';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { DisableTemplatePaddingOn, HeadingTag, ContentfulBackground, Sizes } from '@/types';
import { useTemplateContainerFullWidthStyles, textToJumpLink } from '@/utils';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import ContentfulButton from '@/components/ContentfulButton';
import { resolveContentfulBgColor, translateTagColorNameForContentful } from '@/utils';

import TemplateContainer from '../TemplateContainer';
import { IImageAsset } from '../ImageAsset/ImageAsset';

import {
	getSectionWrapperStyles,
	getCardsWrapperStyles,
	wrapper,
	getHeadingStyles,
	richTextDescStyles,
	getMultiCardButtonsStyles,
	backgroundContainerStyles,
	multiCardChipStyles,
} from './MultiCard.styles';
import CardItem from './CardItem';
import { LIMIT_OF_MULTI_CARD_IN_ROW_FOR_DESKTOP, collectUniqueTags, filterCardsByTag } from './MultiCard.helpers';

// NOTE: We need to import this dynamically to handle the issue with the Swiper library.
// It's ESM only and if not imported dynamically it will be require()d, causing the ERR_REQUIRE_ESM error.
// As we do not need this component on the server, we use ssr: false to disable it.
const CardCarousel = dynamic(() => import('./CardCarousel/CardCarousel'), { ssr: false });

export interface IMultiCard extends MultiCardV2 {
	/**
	 * Specifies the background color of the template.
	 *
	 * @type {Maybe<TAGBackground>}
	 * @memberof IMultiCard
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies if the background image should be displayed on big screens.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2
	 */
	bigScreenBackground?: Maybe<boolean>;
	/**
	 * Specifies the text alignment of the cards.
	 *
	 * @type {Maybe<flex-start | center>}
	 * @memberof IMultiCard
	 */
	cardsAlignment?: Maybe<string>;
	/**
	 * Cards to be rendered.
	 *
	 * @type {Maybe<MultiCardV2CardsCollection>}
	 * @memberof IMultiCard
	 */
	cardsCollection?: Maybe<MultiCardV2CardsCollection>;
	/**
	 * Template section text description.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 */
	description?: Maybe<string>;
	/**
	 * Template section rich text description
	 *
	 * @type {Maybe<MultiCardV2DescriptionRichText>}
	 * @memberof IMultiCard
	 */
	descriptionRichText?: Maybe<MultiCardV2DescriptionRichText>;
	/**
	 * Specifies the eyebrow color.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2
	 */
	eyebrowColor?: Maybe<string>;
	/**
	 * Specifies the eyebrow SX styles. Should not be used.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2
	 */
	eyebrowSx?: Maybe<string>;
	/**
	 * Specifies the eyebrow text transform. Should not be used.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2. This should be handled by the CMS.
	 */
	eyebrowTextTransform?: Maybe<string>;
	/**
	 * Specifies the layout used on small screens. Can be 'carousel' or 'stack'.
	 *
	 * @type {Maybe<'carousel' | 'stack'>}
	 * @memberof IMultiCard
	 */
	forMobile?: Maybe<string>;
	/**
	 * Specifies if navigation is used on the carousel on small screens.
	 *
	 * @type {boolean}
	 * @memberof IMultiCard
	 */
	enableMobileCarouselNavigation?: boolean;
	/**
	 * Specifies card content to appear in a hoverable box on the card itself.
	 *
	 * @type {boolean}
	 * @memberof IMultiCard
	 */
	enableResourceCardMode?: boolean;
	/**
	 * lastRowCardsAlignment will be applied for more than 3 cards and in desktop mode.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2
	 */
	lastRowCardsAlignment?: Maybe<string>;
	/**
	 * Specifies the number of cards in a row on desktop.
	 *
	 * @type {Maybe<number>}
	 * @memberof IMultiCard
	 */
	numberOfCardsInLineDesktop?: Maybe<number>;
	/**
	 * Specifies whether the top borders of the image should be rounded.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IMultiCard
	 * @deprecated not used in MultiCardV2
	 */
	roundedImage?: Maybe<boolean>;
	/**
	 * Specifies the section eyebrow text.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 */
	sectionEyebrow?: Maybe<string>;
	/**
	 * Specifies the text alignment for the section info.
	 *
	 * @type {Maybe<flex-start | center>}
	 * @memberof IMultiCard
	 */
	sectionInfoAlignment?: Maybe<string>;
	/**
	 * Specifies the section title text.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMultiCard
	 */
	sectionTitle?: Maybe<string>;
	/**
	 * Optional setting for choosing the heading type.
	 *
	 * @type {Maybe<HeadingTag>}
	 * @memberof IMultiCard
	 */
	sectionTitleHtag?: Maybe<HeadingTag>;
	/**
	 * Optional data-test-id attribute for testing purposes.
	 *
	 * @type {string}
	 * @memberof IMultiCard
	 */
	dataTestId?: string;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IMultiCard
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * The collection of buttons to display at the bottom of component.
	 *
	 * @type {Maybe<MultiCardV2ButtonsCollection>}
	 * @memberof IMultiCard
	 */
	buttonsCollection?: Maybe<MultiCardV2ButtonsCollection>;
	/**
	 * An option to display multicard template as stepper.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IMultiCard
	 */
	displayAsStepper?: boolean;
	/**
	 * The image to display. The reference to an image asset in Contentful.
	 * @type {Maybe<ImageAsset>}
	 * @memberof IMultiCard
	 */
	backgroundImage?: Maybe<ImageAsset>;

	/**
	 * Enable repeat for background image.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IMultiCard
	 */
	fixBackgroundToBottom?: Maybe<boolean>;

	/**
	 * Determines spacing between header and content.
	 *
	 * @type {Maybe<Sizes>}
	 * @memberof IMultiCard
	 */
	headerSpacing?: Maybe<Sizes>;

	/**
	 * An option to display cards in carousel.
	 *
	 * @type {Maybe<Boolean>}
	 * @memberof IMultiCard
	 */
	shouldDisplayCarouselOnDesktop?: Maybe<boolean>;

	/**
	 * An option to display cards with rounded corners.
	 *
	 * @type {Maybe<Boolean>}
	 * @memberof IMultiCard
	 */
	useCardMediaRoundedCorners?: Maybe<boolean>;
}

export default function MultiCard({
	dataTestId = 'section_multi_card',
	backgroundColor,
	sectionEyebrow,
	sectionTitle,
	sectionTitleHtag = 'h1',
	description,
	descriptionRichText,
	sectionInfoAlignment,
	cardsCollection,
	cardsAlignment,
	forMobile,
	enableMobileCarouselNavigation = false,
	enableResourceCardMode = false,
	disablePaddingOn,
	numberOfCardsInLineDesktop,
	buttonsCollection,
	displayAsStepper = false,
	backgroundImage,
	headerSpacing = 'Medium',
	fixBackgroundToBottom = false,
	shouldDisplayCarouselOnDesktop = false,
	useCardMediaRoundedCorners = false,
}: IMultiCard) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // < 900px
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // < 600px

	// solve issue with variant naming structure ie. lightGradient vs Light Gradient
	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor || '') || '';
	const contentfulBackgroundColor = resolveContentfulBgColor(
		contentfulSafeBackgroundColor as ContentfulBackground,
		theme
	);
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');

	const cards = cardsCollection?.items;
	const bottomButtons = buttonsCollection?.items;
	const shouldDisplayCarouselOnMobile = forMobile === 'carousel';
	const paddingStyles = useTemplateContainerFullWidthStyles(
		disablePaddingOn,
		isSmallScreen,
		(isSmallScreen && shouldDisplayCarouselOnMobile) || enableResourceCardMode
	);
	const tags = collectUniqueTags(cards);
	const [selectedTag, setSelectedTag] = useState<string | null>(null);
	const filteredCards = filterCardsByTag(cards, selectedTag);
	const maxNumberOfCardsInARowInDesktop =
		filteredCards?.length && filteredCards?.length > LIMIT_OF_MULTI_CARD_IN_ROW_FOR_DESKTOP
			? LIMIT_OF_MULTI_CARD_IN_ROW_FOR_DESKTOP
			: filteredCards?.length ?? LIMIT_OF_MULTI_CARD_IN_ROW_FOR_DESKTOP;

	const onChipClick = (tagName: string, selectedTagName: string | null) => {
		if (selectedTagName && tagName === selectedTagName) {
			setSelectedTag(null);
		} else {
			setSelectedTag(tagName);
		}
	};

	return (
		<TemplateContainer
			maxWidth={false}
			containerSx={{ ...wrapper }}
			dataTestId={dataTestId}
			contentfulBackgroundColor={contentfulBackgroundColor}
		>
			<Box
				sx={{
					...backgroundContainerStyles(backgroundImage as IImageAsset, fixBackgroundToBottom, isSmallScreen),
					...paddingStyles,
				}}
			>
				<Container sx={getSectionWrapperStyles(sectionInfoAlignment, headerSpacing)}>
					{sectionEyebrow && (
						<Typography
							variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
							color={isDarkBackground ? 'text.light' : 'text.secondary'}
							data-test-id="text_multicard_section_eyebrow"
						>
							{sectionEyebrow}
						</Typography>
					)}

					{sectionTitle && (
						<Typography
							variant={getHeadingVariant(sectionTitleHtag)}
							id={textToJumpLink(sectionTitle)}
							component={
								sectionTitleHtag === 'normal'
									? 'p'
									: sectionTitleHtag === null
									? 'h1'
									: sectionTitleHtag
							}
							sx={{
								...getHeadingStyles(theme),
								color: isDarkBackground ? 'text.light' : 'text.primary',
							}}
							whiteSpace="pre-line"
							data-test-id="text_multicard_section_title"
						>
							{sectionTitle}
						</Typography>
					)}

					{descriptionRichText ? (
						<ContentTypeRichText
							content={descriptionRichText as TContentTypeRichTextContent}
							dataTestId={`text_multicard_section_description_rich_text`}
							disableGutters
							backgroundColor="transparent"
							disablePaddingOn={['Top', 'Bottom']}
							contentMaxWidth="100%"
							docProps={{
								color: isDarkBackground ? 'text.light' : 'text.secondary',
								variant: isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook',
								textAlign: sectionInfoAlignment === 'center' ? 'center' : 'left',
							}}
							containerSx={richTextDescStyles(isDarkBackground)}
						/>
					) : description ? (
						<Typography
							color={isDarkBackground ? 'text.light' : 'text.secondary'}
							whiteSpace="pre-line"
							variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
							data-test-id="text_multicard_section_description"
						>
							{description}
						</Typography>
					) : null}
					{!!tags.length && (
						<Box display="flex" gap="1rem" data-test-id="mulricard_section_tags">
							{tags.map((tag) => (
								<Chip
									key={tag}
									variant={selectedTag === tag ? 'selected' : 'input'}
									sx={multiCardChipStyles}
									onClick={() => onChipClick(tag, selectedTag)}
									clickable
									label={tag}
									data-test-id={`mulricard_section_tag_${tag}`}
								/>
							))}
						</Box>
					)}
				</Container>

				<Container
					disableGutters
					sx={getCardsWrapperStyles({
						isDarkBackground,
						isSmallScreen,
						cardsAlignment,
						numberOfCards: (displayAsStepper
							? filteredCards?.length
							: numberOfCardsInLineDesktop || maxNumberOfCardsInARowInDesktop) as number,
						shouldDisplayCarouselOnMobile,
						displayAsStepper,
						shouldDisplayCarouselOnDesktop,
					})}
					data-test-id="group_multicards_cards"
				>
					{(isSmallScreen && shouldDisplayCarouselOnMobile) || shouldDisplayCarouselOnDesktop ? (
						<CardCarousel
							cards={filteredCards}
							isDarkBackground={isDarkBackground}
							displayAsStepper={displayAsStepper}
							enableMobileCarouselNavigation={enableMobileCarouselNavigation}
							enableResourceCardMode={enableResourceCardMode}
							isSmallScreen={isSmallScreen}
							isExtraSmallScreen={isExtraSmallScreen}
							useCardMediaRoundedCorners={useCardMediaRoundedCorners ?? false}
						/>
					) : (
						filteredCards?.map((card, index: number) =>
							card ? (
								<CardItem
									key={card.sys.id}
									card={card}
									isSmallScreen={isSmallScreen}
									isDarkBackground={isDarkBackground}
									displayAsStepper={displayAsStepper}
									showLeftConnector={index != 0}
									showRightConnector={index != filteredCards.length - 1}
									enableResourceCardMode={enableResourceCardMode}
									useMediaRoundedCorners={useCardMediaRoundedCorners ?? false}
								/>
							) : null
						)
					)}
				</Container>

				{bottomButtons && bottomButtons.length > 0 ? (
					<Box sx={getMultiCardButtonsStyles(isSmallScreen)} data-test-id="multicard_buttons_box">
						{bottomButtons.map((button) =>
							button ? (
								<ContentfulButton
									key={button?.sys?.id}
									{...button}
									dataTestId={`multicard_button_${button?.name || ''}`}
								/>
							) : null
						)}
					</Box>
				) : null}
			</Box>
		</TemplateContainer>
	);
}
