/***
  Safari and Firefox clip or misalign content while Chrome renders it correctly.
  Safari and Firefox handles width calculations differently, leading to only part of the second column being displayed.
**/

const isHorizontallyBound = (parentDiv: HTMLElement, childDiv: Element) => {
	const parentRect = parentDiv.getBoundingClientRect();
	const childRect = childDiv.getBoundingClientRect();

	return parentRect.left >= childRect.left || parentRect.right <= childRect.right;
};

const calculatePadding = (parentDiv: HTMLElement) => {
	const firstElement = parentDiv.children[0];
	const parentRect = parentDiv.getBoundingClientRect();
	const firstElementRect = firstElement.getBoundingClientRect();

	return Math.abs(parentRect.left - firstElementRect.left);
};

const getFirstOutsideOfBoundaryChildElement = (parentDiv: HTMLElement) => {
	return [...parentDiv.children].find((childElement) => isHorizontallyBound(parentDiv, childElement));
};

const calculateContainerWidth = (parentDiv: HTMLElement) => {
	const padding = calculatePadding(parentDiv);
	const firstOutsideOfBoundaryChildElement = getFirstOutsideOfBoundaryChildElement(parentDiv);

	if (!firstOutsideOfBoundaryChildElement) {
		return;
	}

	const sumOfAllPaddings = padding * 4;
	const firstChildRect = parentDiv.children[0].getBoundingClientRect();
	const firstOutsideOfBoundaryChildElementRect = firstOutsideOfBoundaryChildElement.getBoundingClientRect();

	return sumOfAllPaddings + firstChildRect.width + firstOutsideOfBoundaryChildElementRect.width;
};

export { calculateContainerWidth };
