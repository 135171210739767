import { useEffect, useRef, useState } from 'react';

import { Box, SxProps } from '@mui/material';
import { SystemCssProperties, Theme } from '@mui/system';

import { Maybe } from '@/types';

import { getImageWrapperStyles, getIntrinsicRatioWrapperStyles } from './AspectRatioPaddingBox.styles';

const DEFAULT_ASPECT_RATIO = '56.25%'; // 16:9

/**
 * Calculates the padding-bottom hack for the element wrapper.
 *
 * @see https://alistapart.com/article/creating-intrinsic-ratios-for-video/
 * @see https://css-tricks.com/aspect-ratio-boxes/
 * @param {string} aspectRatio
 * @return {*}
 */
function calculatePaddingBottomHack(aspectRatio: string) {
	const splitAspectRatio = aspectRatio.split(':');

	const paddingBottomHack = parseInt(splitAspectRatio[1], 10) / parseInt(splitAspectRatio[0], 10);

	if (Number.isNaN(paddingBottomHack)) {
		return DEFAULT_ASPECT_RATIO;
	}

	return `${paddingBottomHack * 100}%`;
}

export function resolveContentfulAspectRatio(mediaAspectRatio?: Maybe<string>) {
	switch (mediaAspectRatio) {
		case 'Standard (5:4)':
		case 'FiveToFour':
			return '5:4';
		case 'Square (1:1)':
		case 'OneToOne':
			return '1:1';
		case 'Portrait (9:16)':
			return '9:16';
		case 'Landscape (2:1)':
			return '2:1';
		case 'Landscape (16:9)':
			return '16:9';
		default:
			return '16:9';
	}
}

interface IAspectRatioPaddingBox {
	dataTestId?: string;
	children: React.ReactNode;
	aspectRatio?: string;
	contentfulAspectRatio?: Maybe<string>;
	placeholderColor?: SystemCssProperties<Theme>['background'];
	imageWrapperSx?: SxProps;
	paddingBoxSx?: SxProps;
	contentHeight?: number;
}

export default function AspectRatioPaddingBox({
	children,
	aspectRatio = '16:9',
	contentfulAspectRatio,
	dataTestId = 'section_aspect_ratio_padding_box',
	placeholderColor = 'backgrounds.neutral',
	imageWrapperSx = {},
	paddingBoxSx = {},
	contentHeight = 0,
}: IAspectRatioPaddingBox) {
	const boxRef = useRef<HTMLDivElement>(null);
	const derivedAspectRatio = contentfulAspectRatio
		? resolveContentfulAspectRatio(contentfulAspectRatio)
		: aspectRatio;
	const paddingBottomHack = calculatePaddingBottomHack(derivedAspectRatio);
	const [isContentHeightUsed, setContentHeightUsed] = useState(false);

	useEffect(() => {
		// used a content height if it is not fit for AspectRatio '9:16'
		if (derivedAspectRatio === '9:16' && boxRef.current && boxRef.current.clientHeight < contentHeight) {
			setContentHeightUsed(true);
		}
	}, [boxRef, contentHeight, derivedAspectRatio, setContentHeightUsed]);

	return (
		<Box
			ref={boxRef}
			data-test-id={dataTestId}
			sx={getIntrinsicRatioWrapperStyles(
				isContentHeightUsed ? `${contentHeight}px` : paddingBottomHack,
				placeholderColor,
				paddingBoxSx
			)}
		>
			<Box sx={getImageWrapperStyles(imageWrapperSx)}>{children}</Box>
		</Box>
	);
}
