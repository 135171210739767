import { Session } from '@aspendental/shared-components-web';
import Bugsnag from '@bugsnag/js';
import { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import { Faro, FaroErrorBoundary } from '@grafana/faro-react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

import { ContextProviders } from '@src/components/shared/providers/ContextProviders';
import { Layout } from '@src/components/templates/layout';
import { getLogger } from '@src/lib/logging/logger';
import { initBugsnag } from '@src/services/bugsnag';
import { initGrafanaFaro } from '@src/services/faro';
import { useHumankind } from '@src/services/HumankindHandler';

import '@contentful/live-preview/style.css';

import '@aspendental/shared-components-web/lib/dynamic-form.css';
import '@aspendental/shared-components-web/lib/fonts.css';
import '@aspendental/shared-components-web/lib/global.css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
	const logger = getLogger('client');
	logger.info({
		name,
		category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
		id, // id unique to current page load
	});
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
	const router = useRouter();
	const config = pageProps?.config as BrandThemeConfig;
	const { features, featureFlags } = config as BrandThemeConfig;
	const brandName = config?.name || 'Aspen Dental';
	initBugsnag(pageProps.env, brandName);
	const brazeEnabled = config.features.analytics.braze.enabled;
	const brazeSdkKey = config.features.analytics.braze.sdkKey;
	const brazeBaseUrl = config.features.analytics.braze.baseUrl;
	const brazeLoggingEnabled = config.features.analytics.braze.enableLogging;

	const BugsnagReactPlugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
	const BugsnagErrorBoundary = BugsnagReactPlugin.createErrorBoundary(React);
	const faroRef = useRef<null | Faro>(null);

	// regular page and blog page
	const disableStickyFooterOnMobile =
		pageProps.page?.disableStickyFooterOnMobile || pageProps.pageData?.disableStickyFooterOnMobile;

	//enable humankind currently just for clearChoice
	useHumankind(brandName);

	useEffect(() => {
		if (brazeEnabled) {
			import('@src/pages/utils/braze-exports').then(
				({ initialize, openSession, automaticallyShowInAppMessages }) => {
					initialize(brazeSdkKey, {
						baseUrl: brazeBaseUrl,
						enableLogging: brazeLoggingEnabled,
						allowUserSuppliedJavascript: true,
					});
					automaticallyShowInAppMessages();
					openSession();
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brazeEnabled]);

	useEffect(() => {
		if (!faroRef.current && config?.features?.realUserMonitoring?.enabled) {
			faroRef.current = initGrafanaFaro(config.features.realUserMonitoring.faroApiKey);
		}
	}, [config?.features?.realUserMonitoring]);

	useEffect(() => {
		// when component is mounting we remove server side rendered css:
		const jssStyles = document.querySelector('#jss-server-side');

		if (jssStyles && jssStyles.parentNode) {
			jssStyles.parentNode.removeChild(jssStyles);
		}

		router.events.on('routeChangeComplete', () => null);

		return () => {
			router.events.off('routeChangeComplete', () => null);
		};
	}, [router.events]);
	const getLayout =
		Component.getLayout ??
		((page) => {
			return (
				<Layout
					locale={router.locale || 'en-US'}
					preview={pageProps.preview}
					appData={pageProps.appData}
					isFooterLite={features.footer.lite}
					isFooterAlt={features.footer.alt}
					withSimpleLayout={features.footer.withSimpleLayout}
					footerData={pageProps.footerData}
					showHeaderNavMenu={featureFlags?.showHeaderNavigationMenu || false}
					breadcrumbsData={pageProps.breadcrumbsData}
					withFixedHeader={!features.header.hideHeaderOnScroll}
					disableStickyFooterOnMobile={disableStickyFooterOnMobile}
				>
					{page}
				</Layout>
			);
		});
	const environment = pageProps?.env?.ENVIRONMENT_NAME || 'prod';
	const dentrinoClientId = pageProps?.env?.DENTRINO_CLIENT_ID || '';
	const dentrinoClientSecret = pageProps?.env?.DENTRINO_CLIENT_SECRET || '';
	return (
		<BugsnagErrorBoundary>
			<FaroErrorBoundary>
				<Session>
					<ContextProviders
						config={pageProps.config}
						preview={pageProps.preview}
						environment={environment}
						user={pageProps.user}
						onPageScheduling={featureFlags?.onPageScheduling || false}
						pageTheme={pageProps?.page?.pageTheme}
						defaultToCdn={pageProps?.config?.featureFlags?.defaultToCdn}
						dentrinoClientId={dentrinoClientId}
						dentrinoClientSecret={dentrinoClientSecret}
						deviceType={pageProps.deviceType}
					>
						{getLayout(<Component {...pageProps} />)}
					</ContextProviders>
				</Session>
			</FaroErrorBoundary>
		</BugsnagErrorBoundary>
	);
};

export default appWithTranslation(App);
