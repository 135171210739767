import { Maybe } from '@/types';
import { BffFacility, FacilityBrand, FacilityDailyWorkingHours, NearbyFacility } from '@/types/generated';

export const mapFacilities = (facilities: (BffFacility | null)[] | null | undefined): NearbyFacility[] => {
	return (
		facilities?.map((facility) => ({
			distanceInMiles: facility?.location?.distance,
			facility: {
				address: {
					address1: facility?.address?.address1 ?? null,
					address2: facility?.address?.address2 ?? null,
					city: facility?.address?.city ?? null,
					zipCode: facility?.address?.zipCode ?? null,
					stateCode: facility?.address?.stateCode ?? null,
				},
				brand: facility?.brand as FacilityBrand,
				code: facility?.code ?? '',
				comingSoon: facility?.comingSoon ?? false,
				location: {
					address1: facility?.address?.address1 ?? null,
					address2: facility?.address?.address2 ?? null,
					city: facility?.address?.city ?? null,
					zipCode: facility?.address?.zipCode ?? null,
					stateCode: facility?.address?.stateCode ?? null,
					longitude: facility?.location?.longitude ?? 0,
					latitude: facility?.location?.latitude ?? 0,
					timeZone: facility?.location?.timeZone,
					landmarks: facility?.location?.landmarks,
					neighbouringAreas: facility?.location?.neighboringAreas,
					dst: false,
				},
				name: facility?.name ?? '',
				officeWorkingHours: [],
				scheduling: {
					schedulingUrl: facility?.scheduleAppointmentURL,
				},
				technicalInfrastructure: { phoneNumber: facility?.phoneNumber },
				status: facility?.status,
				workingHours: facility?.workingHours as Maybe<Array<FacilityDailyWorkingHours>>,
				openDate: facility?.openDate ?? null,
			},
		})) ?? []
	);
};
