import { type SystemStyleObject } from '@mui/system';

import type { Theme } from '@mui/material';

export const getImageWrapperStyles = (theme: Theme) => ({
	width: { xs: 'calc(100% + 2.5rem)', md: '50%' },
	maxWidth: { md: '50%' },
	height: { xs: 'auto', md: '100vh' },
	[theme.breakpoints.down('md')]: {
		marginTop: '1.5rem',
	},
});

export const getContainerStyles = () => ({
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
});

export const getContentContainerStyles = (theme: Theme): SystemStyleObject<Theme> => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: { xs: 'center', md: 'flex-start' },
	alignItems: { xs: 'center', md: 'flex-start' },
	gap: '1rem',
	px: 3,
	py: '2.5rem',
	[theme.breakpoints.up('md')]: {
		maxHeight: '100vh',
		overflowY: 'scroll',
		width: '50%',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		px: '2%',
		py: '3.75rem',
	},
	[theme.breakpoints.up('lg')]: {
		px: '5%',
	},
});

export const shareContentStyles = (theme: Theme) => ({
	display: 'flex',
	paddingTop: { xs: '1.25rem', md: '2.5rem' },
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: '1rem',
	alignSelf: 'stretch',
	borderTop: '0.063rem solid',
	borderColor: theme.palette.neutrals?.primary,
});

export const socialMediaLinkStyles = {
	display: 'flex',
	alignItems: 'flex-start',
	gap: '1.25rem',
	flexWrap: 'wrap',
	zIndex: 1,
};

export const drawerStyles = {
	'.MuiDrawer-paperAnchorBottom': {
		display: 'flex',
		height: '28.938rem',
		padding: '0 1.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		flexShrink: 0,
		borderRadius: '1rem 1rem 0 0',
	},
};

export const pullerBoxStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '3rem',
	height: '3rem',
	flexShrink: 0,
};

export const mobileIconContainer = {
	display: 'flex',
	paddingTop: '1.75rem',
	gap: '1.25rem',
	flexWrap: 'wrap',
};

export const mobileShareableLinkStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '.25rem',
	width: '4.297rem',
	flex: '1 0 0',
};
export const mobileTitleStyles = { textAlign: 'center' };
export const shareIconStyles = { fontSize: 20 };

export const blogAuthorCtrStyles = {
	marginY: '2.5rem',
	paddingY: { xs: '1rem', md: '0' },
	alignSelf: 'flex-start',
};

export const blogAuthorNameStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '.5rem',
};

export const blogAuthorSummaryStyles = {
	marginY: '1rem',
};

export const blogAuthorImageNameCtrStyles = {
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
	gap: '1rem',
};

export const blogAuthorImageCtrStyles = {
	height: '5rem',
	width: '5rem',
	borderRadius: '50%',
	backgroundColor: 'backgrounds.neutral',
};

export const blogAuthorImageStyles = {
	height: '5rem',
	width: '5rem',
	borderRadius: '50%',
};

export const mobileOnly = (theme: Theme): SystemStyleObject<Theme> => ({
	[theme.breakpoints.up('md')]: {
		display: 'none',
	},
});

export const desktopOnly = (theme: Theme): SystemStyleObject<Theme> => ({
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
});
