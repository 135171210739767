import { Box } from '@mui/material';

import { Maybe } from '@/types';
import { ImageAsset as TImageAsset } from '@/types/generated';

import ImageAsset, { IImage } from '../ImageAsset/ImageAsset';

import { sideImageBoxStyles } from './SingleMessage.styles';

export const SideImage = ({
	imageAsset,
	side = 'left',
	isSmallScreen,
}: {
	imageAsset?: TImageAsset;
	side?: 'left' | 'right';
	isSmallScreen?: Maybe<boolean>;
}) => {
	const widthHeightSize = isSmallScreen ? '50%' : '100%';
	const objectFit = isSmallScreen ? 'none' : 'cover';

	return (
		<Box sx={sideImageBoxStyles(!!isSmallScreen)}>
			<ImageAsset
				desktopImage={imageAsset?.desktopImage as IImage}
				mobileImage={imageAsset?.mobileImage as IImage}
				isBackgroundImage={false}
				imageSx={{ 'object-fit': objectFit, height: widthHeightSize, width: widthHeightSize }}
				dataTestId={`single_message_sideimage-${side}${isSmallScreen ? '-mobile' : ''}`}
			/>
		</Box>
	);
};
