const DetermineIndexing = (
	pageIndexingRequested: boolean,
	env: Record<string, string>,
	preview: boolean,
	doNotIndex: boolean
) => {
	// Always noindex, nofollow if page is a preview
	if (preview) {
		return false;
	}

	// If app deems the whole site to be not indexed
	if (doNotIndex) {
		return false;
	}

	return pageIndexingRequested && env?.ENVIRONMENT_NAME === 'prod';
};

export default DetermineIndexing;
