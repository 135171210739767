import { Sizes } from '@/types';

export const changeSymbol = (str: string) => {
	const copyright = str.replace(/\(C\)*/gi, '©');
	const tm = copyright.replace(/\(TM\)*/gi, '™');
	const registered = tm.replace(/\(R\)*/gi, '®');

	return registered;
};

export const iconSizeNumber = (size?: Sizes): number => {
	const sizes = {
		Small: 32,
		Medium: 48,
		Large: 64,
		XLarge: 68,
	} as { [key: string]: number };
	return sizes[size ?? 'Large'];
};
