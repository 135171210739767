import { Maybe } from '@/types';

export const templateWrapperStyles = (fullWidth: boolean, useRoundedCorners: boolean) => ({
	position: 'relative',
	margin: '0 auto',
	display: 'flex',
	flexFlow: 'column',
	borderRadius: useRoundedCorners && !fullWidth ? '0.5rem' : '',
});

export const getContentWrapperStyles =
	(
		isSmallScreen: boolean,
		isContentCenterAligned: boolean,
		inlineLayout: Maybe<boolean>,
		removePadding: Maybe<boolean>,
		hasSideImage: Maybe<boolean>
	) =>
	() => {
		const contentDynamicStyles = inlineLayout
			? getContentWrapperInlineStyles(isSmallScreen, isContentCenterAligned)
			: {
					flexDirection: 'column',
					justifyContent: isSmallScreen ? 'flex-start' : 'center',
					alignItems: isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
					gap: '0.625rem',
					px: isContentCenterAligned || removePadding ? null : { xs: 3, md: '2%', lg: '5%' },
					margin: hasSideImage ? '0' : '0 auto',
					width: hasSideImage ? '37rem' : '100%',
					flex: hasSideImage ? '0 1 auto' : 1,
			  };
		return {
			position: 'relative',
			display: 'flex',
			...contentDynamicStyles,
		};
	};

export const getContentWrapperInlineStyles = (isSmallScreen: boolean, isContentCenterAligned: boolean) => ({
	flexDirection: 'row',
	justifyContent: isSmallScreen && isContentCenterAligned ? 'center' : 'flex-start',
	alignItems: !isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
	gap: '1rem',
	py: { xs: 3, md: '2%' },
	px: { xs: 3, md: '2%' },
	'& > [data-test-id="group_single_message_buttons_wrapper"]': {
		marginLeft: 'auto',
	},
});

export const getBodyContainerStyles = (isSmallScreen: boolean) => ({
	'.MuiContainer-root': {
		paddingLeft: '0',
		paddingRight: '0',
	},
	'& > ul': {
		paddingLeft: '0.3rem',
		listStylePosition: 'inside',
		listStyleType: { xs: 'none', md: 'disc' },
		fontSize: { xs: '0.875rem', md: '1rem' },
	},
	'& > ul li p': {
		m: 0,
		lineHeight: '2rem',
		display: 'inline-block',
		...(isSmallScreen
			? {
					'&:before': {
						content: '"⦁ "',
					},
			  }
			: {}),
	},
});

export const getContentAlignStyles = (
	isContentCenterAligned: boolean,
	inlineLayout: Maybe<boolean>,
	isSmallScreen: Maybe<boolean>
) =>
	inlineLayout
		? getContentAlignInlineStyles(isContentCenterAligned, isSmallScreen)
		: {
				display: 'flex',
				justifyContent: isContentCenterAligned ? 'center' : 'flex-start',
				alignItems: isContentCenterAligned ? 'center' : 'flex-start',
				alignContent: isContentCenterAligned ? 'center' : 'flex-start',
				width: isContentCenterAligned ? null : '100%',
				maxWidth: isContentCenterAligned ? null : '70rem',
		  };

export const getContentAlignInlineStyles = (isContentCenterAligned: boolean, isSmallScreen: Maybe<boolean>) => ({
	display: 'flex',
	flexDirection: isSmallScreen ? 'column' : '',
	alignItems: !isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
	width: 'auto',
});

export const getSingleMessageRichTextContainerStyles = (isContentCenterAligned: boolean) => ({
	width: '100%',
	maxWidth: '70rem',
	textAlign: isContentCenterAligned ? 'center' : 'left',
});

export const getSingleMessageButtonsWrapper = (isContentCenterAligned: boolean, inlineLayout: Maybe<boolean>) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: { xs: '0.5rem', md: '1rem' },
	padding: inlineLayout
		? { xs: '2rem 0 0', md: '0' }
		: { xs: isContentCenterAligned ? '0 0.375rem 0.75rem' : '0 0.375rem 0.75rem 0', md: '0 0 1.25rem 0' },
	margin: inlineLayout ? 0 : { xs: '0 auto', md: 0 },
	width: isContentCenterAligned || inlineLayout ? null : '100%',
	maxWidth: isContentCenterAligned ? null : '70rem',
	justifyContent: isContentCenterAligned ? 'center' : 'flex-start',
});

export const backgroundImageStyles = {
	display: 'block',
	backgroundSize: 'cover',
	position: 'absolute',
	top: 0,
	left: 0,
	backgroundRepeat: 'no-repeat',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
};

export const bannerImageStyles = {
	width: '100%',
	height: 'auto',
	minHeight: '0',
	position: 'static',
	display: 'block',
	objectFit: 'unset',
	objectPosition: '',
};

export const sideImageWrapperStyles = {
	display: 'flex',
	flexFlow: 'row nowrap',
	zIndex: 1,
	justifyContent: 'center',
};

export const sideImageMobileWrapperStyles = {
	display: 'flex',
	flexFlow: 'row no-wrap',
	justifyContent: 'center',
	padding: '0 1.5rem',
};

export const sideImageBoxStyles = (isSmallScreen: Maybe<boolean>) => ({
	flex: isSmallScreen ? '1 1 50%' : '0 1 14.125rem',
	display: 'flex',
	flexFlow: 'row nowrap',
	justifyContent: 'center',
	alignItems: 'center',
});
