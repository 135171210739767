import { Maybe } from '@/types';
import { FocalPointImage as TFocalPointImage, ImageAsset as TImageAsset } from '@/types/generated';
import ImageAsset from '@/components/ImageAsset';

import { FocalPointData } from '../FocalPointImage';
import FocalPointImage from '../FocalPointImage';
import { IImage } from '../ImageAsset/ImageAsset';

import { backgroundImageStyles, bannerImageStyles } from './SingleMessage.styles';

export const RenderImage = ({
	imageAsset,
	focalPointImage,
	focalPointImageSmallScreen,
	isBannerImage,
	isSmallScreen,
}: {
	imageAsset?: Maybe<TImageAsset>;
	focalPointImage?: Maybe<TFocalPointImage>;
	focalPointImageSmallScreen?: Maybe<TFocalPointImage>;
	isBannerImage: boolean | null | undefined;
	isSmallScreen: boolean;
}) => {
	const bgImage =
		isSmallScreen && focalPointImageSmallScreen ? focalPointImageSmallScreen?.image : focalPointImage?.image;

	const focalPoint = (
		isSmallScreen && focalPointImageSmallScreen
			? focalPointImageSmallScreen?.focalPoint
			: focalPointImage?.focalPoint
	) as FocalPointData;
	const singleMessageImageStyles = isBannerImage ? bannerImageStyles : backgroundImageStyles;

	return (
		<>
			{imageAsset && (
				<ImageAsset
					desktopImage={imageAsset?.desktopImage as IImage}
					mobileImage={imageAsset?.mobileImage as IImage}
					imageSx={singleMessageImageStyles}
					isBackgroundImage={!isBannerImage}
					dataTestId={
						isBannerImage ? 'img_asset_single_message_banner' : 'img_asset_single_message_background'
					}
				/>
			)}
			{focalPointImage && !imageAsset && (
				<FocalPointImage
					image={bgImage}
					backgroundImage
					focalPoint={focalPoint}
					imageSx={backgroundImageStyles}
					dataTestId="focal_point_image"
				/>
			)}
		</>
	);
};
